/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import http from 'components/http';

const ModalExcluirRegistro = ({ show, onHide, registro }) => {
  const navigate = useNavigate();

  const excluirRegistro = () => {
    http
      .request({
        url: 'apontamentos/' + registro.id,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        toast.success('Apontamento excluído com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });

        let datas = registro?.data_atividade.split('-');
        let mes = '';
        if (parseInt(datas[1]) < 10) mes = datas[1].replace('0', '');
        else mes = datas[1];

        navigate(
          `/apontamentos/usuarios/${registro?.usuario?.id}/${datas[0]}/${mes}`
        );
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });

          onHide();
        } else {
          toast.error('Erro ao excluir o apontamento!', {
            theme: 'colored',
            position: 'top-right'
          });

          onHide();
        }
      });
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <h5>Deseja realmente excluir esse apontamento?</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={excluirRegistro}>Sim</Button>
        <Button onClick={onHide} variant="outline-primary">
          Não
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalExcluirRegistro;
