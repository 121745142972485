import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import http from 'components/http';
import LogForm from './LogForm';
import InfoForm from './InfoForm';
import ModalExcluirRegistro from './ModalExcluirRegistro';
import { toast } from 'react-toastify';

const schema = yup
  .object({
    projeto: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    usuario: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    tarefa: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    cargo: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    hora_inicio: yup
      .string()
      .max(5, 'Campo inválido')
      .required('Esse campo é obrigatório'),
    hora_fim: yup
      .string()
      .max(5, 'Campo inválido')
      .required('Esse campo é obrigatório'),
    tempo_descontado: yup
      .string()
      .max(5, 'Campo inválido')
      .optional()
      .nullable(),
    dia_atividade: yup
      .number()
      .max(31, 'Dia do mês inválido')
      .required('Esse campo é obrigatório'),
    desc_atividade: yup.string().required('Esse campo é obrigatório')
  })
  .required();

const ApontamentoForm = () => {
  const navigate = useNavigate();
  const [showModalExcluir, setShowModalExcluir] = useState(false);
  const [tarefas, setTarefas] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [apontamento, setApontamento] = useState({
    id: '',
    usuario: '',
    tarefa: '',
    cargo: '',
    projeto: '',
    data_atividade: '',
    hora_inicio: '',
    hora_fim: '',
    tempo_descontado: '',
    qtde_horas_tarefa_nominal: '',
    qtde_horas_tarefa_relativa: '',
    desc_atividade: '',
    usuario_insert: '',
    usuario_update: '',
    data_hora_insert: '',
    data_hora_update: ''
  });

  const { id } = useParams();

  useEffect(() => {
    http
      .get('apontamentos/' + id)
      .then(resposta => {
        setApontamento(resposta.data);
        let usuarioId = resposta?.data?.usuario?.id;
        http
          .get('consultores/cargos/usuarios/' + usuarioId)
          .then(resposta => {
            setCargos(resposta.data);
          })
          .catch(erro => {
            if (erro.request.status === 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('user');

              navigate('/auth/entrar');
            } else if (erro.request.status === 403) {
              toast.error('Você não possui permissão para aquela ação', {
                theme: 'colored',
                position: 'top-right'
              });

              navigate('/');
              //window.location.reload();
            } else {
              console.log(erro);
              toast.error('Erro ao listar as tarefas do consultor', {
                theme: 'colored',
                position: 'top-right'
              });
            }
          });

          http
          .get('tarefas/usuarios/' + usuarioId)
          .then(resposta => {
            setTarefas(resposta.data);
          })
          .catch(erro => {
            if (erro.request.status === 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('user');

              navigate('/auth/entrar');
            } else if (erro.request.status === 403) {
              toast.error('Você não possui permissão para aquela ação', {
                theme: 'colored',
                position: 'top-right'
              });

              navigate('/');
              //window.location.reload();
            } else {
              console.log(erro);
              toast.error('Erro ao listar as tarefas do consultor', {
                theme: 'colored',
                position: 'top-right'
              });
            }
          });
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar o consultor', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setError } = methods;

  const onSubmit = data => {
    let datas = apontamento?.data_atividade?.split('-');
    data['data_atividade'] = `${datas[0]}-${datas[1]}-${data['dia_atividade']}`;
    //delete data['usuario'];
    console.log(data);
    http
      .request({
        url: 'apontamentos/' + id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(resposta => {
        setApontamento(resposta.data);

        toast.success('Apontamento editado com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao cadastrar o apontamento!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }
        }
      });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">
                        Apontamento - {apontamento?.data_atividade} -{' '}
                        {apontamento?.hora_inicio} às {apontamento?.hora_fim}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="link"
                        type="button"
                        className="text-secondary me-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Voltar
                      </Button>
                      <Button
                        size="sm"
                        variant="falcon-danger"
                        className="me-3"
                        onClick={() => setShowModalExcluir(true)}
                      >
                        Excluir apontamento
                      </Button>
                      <Button size="sm" variant="primary" type="submit">
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <InfoForm tarefas={tarefas} apontamento={apontamento} cargos={cargos}/>
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <LogForm registro={apontamento} />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <ModalExcluirRegistro
        show={showModalExcluir}
        onHide={() => setShowModalExcluir(false)}
        registro={apontamento}
      />
    </Fragment>
  );
};

export default ApontamentoForm;
